// export interface Note {
//   sendingMethod: Select;
//   petsAllowed: Bool;
//   petDetail: Text;
//   keyType: Select;
//   keyDetail: Text;
//   keyPriceRequired: Bool;
//   keyPrice: Text;
//   hasStamp: Bool;
//   IsProxy: Bool;
//   neighborhoodAssociation: Text;
//   autoLockNumber: Text;
//   garbageCollectionRequired: Bool;
//   garbageCollectionDetail: Text;
//   hasSpecialContract: Bool;
//   content: Formated;
// }

export interface Note {
  [key: string]: Select | Bool | Text | Formated;
}

export interface Text {
  name: string;
  type: "text";
  value: string;
  hint: string;
}

export interface Formated {
  name: string;
  type: "formated";
  value: string;
  hint: string;
}

export interface Select {
  name: string;
  type: "select";
  value: string;
  options: string[];
  hint: string;
}

export interface Bool {
  name: string;
  type: "bool";
  value: boolean;
  hint: string;
}

export const note: Note = {
  sendingMethod: {
    name: "書類送付方法",
    type: "select",
    value: "",
    hint: "",
    options: [
      "レターパック（赤）",
      "レターパックライト（青）",
      "佐川",
      "郵便",
      "持参",
    ],
  },
  managementFee: {
    name: "新規管理委託料",
    type: "text",
    value: "賃料の1か月分+TAX(最低30,000円)",
    hint: "",
  },
  petsAllowed: {
    name: "ペット",
    type: "bool",
    value: false,
    hint: "",
  },
  petDetail: {
    name: "ペット詳細",
    type: "text",
    value: "",
    hint: "",
  },
  keyDetail: {
    name: "カギ詳細",
    type: "text",
    value: "",
    hint: "",
  },
  keyPriceRequired: {
    name: "カギ交換費入居者負担",
    type: "bool",
    value: false,
    hint: "",
  },
  keyPrice: {
    name: "カギ交換費",
    type: "text",
    value: "",
    hint: "",
  },
  hasStamp: {
    name: "印鑑",
    type: "bool",
    value: false,
    hint: "",
  },
  IsProxy: {
    name: "代理契約",
    type: "bool",
    value: false,
    hint: "",
  },
  neighborhoodAssociation: {
    name: "町会情報",
    type: "text",
    value: "",
    hint: "",
  },
  autoLockNumber: {
    name: "オートロック番号",
    type: "text",
    value: "",
    hint: "",
  },
  garbageCollectionRequired: {
    name: "ゴミ代行",
    type: "bool",
    value: false,
    hint: "",
  },
  garbageCollectionDetail: {
    name: "ゴミ代行詳細",
    type: "text",
    value: "",
    hint: "",
  },
  townFee: {
    name: "町費",
    type: "text",
    value: "",
    hint: "",
  },
  townFeeBillingMethod: {
    name: "町費請求方法",
    type: "select",
    value: "",
    hint: "その他の場合は備考に記入",
    options: ["共益費に込み", "町費として請求", "町会から直接請求", "その他"],
  },
  hasSpecialContract: {
    name: "特約",
    type: "bool",
    value: false,
    hint: "",
  },
  content: {
    name: "備考",
    type: "formated",
    value: "",
    hint: "Markdown記法が使えます",
  },
};
