
import Vue, { PropType } from "vue";

import { marked } from 'marked'

export default Vue.extend({
  props: {
    content: {
      type: String,
      required: true
    }
  },
  computed: {
    marked():string {
      if (this.content) {
        return marked.parse(this.content);
      }

      return "";
    }
  }
});
