
import Vue, { PropType } from "vue";

import http from "@/http";
import { note, Note } from "@/note";
import { Agreement, Breadcrumb } from "@/interfaces";
import MyPreview from "@/components/MyPreview.vue";

export interface DataType {
  message: string;
  note: Note;
}

export default Vue.extend({
  components: { MyPreview },
  props: {
    agreement: {
      type: Object as PropType<Agreement>,
      required: true,
    },
  },
  data(): DataType {
    return {
      message: "",
      note: JSON.parse(JSON.stringify(note)),
    };
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "建物",
          disabled: false,
          to: "/buildings",
        },
        {
          text: `${this.agreement.building.name}`,
          disabled: false,
          to: `/building/${this.agreement.building.id}/property`,
        },
        {
          text: "詳細情報編集",
          disabled: false,
        },
      ];
    },
    valid(): boolean {
      return this.message.length > 0;
    },
  },
  mounted() {
    if (this.agreement.property == null) {
      return;
    }

    const json = JSON.parse(this.agreement.property.note) as Note;

    for (const [key, value] of Object.entries(json)) {
      if (this.note[key]) {
        this.note[key].value = value.value;
      }
    }
  },
  methods: {
    async click() {
      const url = `agreements/${this.agreement.id}/properties`;

      await http.post(url, {
        message: this.message,
        note: JSON.stringify(this.note),
      });

      await this.$router.push(
        `/building/${this.agreement.building_id}/property`
      );
    },
  },
});
